<template>
  <div v-if="!islogin" style="padding-top: 50px">
    <img
      src="../assets/images/logo.png"
      alt=""
      style="width: 100px; height: 100px"
    />
    <div style="padding: 20px 0">濮阳市总工会洗车商家核销平台</div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
      </van-cell-group>
      <div style="margin: 16px; padding-top: 20px">
        <van-button round block type="primary" native-type="submit"
          >提交</van-button
        >
      </div>
      <div style="font-size: 12px; color: gray">客服电话：18838963337</div>
    </van-form>
  </div>
  <div v-else>
    <div style="padding: 60px 0">
      <van-cell-group inset>
        <van-field
          v-model="hxm"
          center
          :clearable="true"
          label="核销码"
          placeholder="请输入6位核销码"
          maxlength="6"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="dohxm"
              :disabled="dobutoon"
              >核销</van-button
            >
          </template>
        </van-field>
      </van-cell-group>

      <div
        style="
          text-align: left;
          padding-left: 20px;
          padding-top: 20px;
          font-size: 14px;
          color: gray;
        "
      >
        *核销码英文字母不区分大小写
      </div>
    </div>
    <div
      style="
        text-align: center;
        position: fixed;
        bottom: 80px;
        width: 100%;
        margin: 0 auto;
      "
    >
      <div style="padding: 0 20px">
        <van-row gutter="20" justify="center">
          <van-col span="12"
            ><van-button
              round
              block
              type="success"
              native-type="submit"
              @click="look"
              >查看核销数量</van-button
            ></van-col
          >
          <van-col span="12"
            ><van-button
              round
              block
              type="danger"
              native-type="submit"
              @click="logout"
              >注销登陆</van-button
            ></van-col
          >
        </van-row>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { Http } from "../libs/http";
import { Toast, Dialog } from "vant";

const dobutoon = ref(false);
const username = ref();
const password = ref();
const hxm = ref();
const dohxm = () => {
  dobutoon.value = true;
  // Toast.fail("核销码无效");

  if (hxm.value === undefined || hxm.value.length < 6) {
    Toast.fail("核销码无效");
  } else {
    Http.post("?s=App.PyszghMark_PyszghMark.UpXChxm", {
      hxm: hxm.value,
      username: window.localStorage.getItem("username"),
    }).then((resp) => {
      if (resp.data.state === true) {
        Toast.success("已成功核销");
        hxmtotal();
      } else {
        Toast.fail("已核销或已超期");
      }
    });
  }

  setTimeout(function () {
    dobutoon.value = false;
  }, 2000);
};

const onSubmit = () => {
  Http.post("?s=App.PyszghMark_PyszghMark.Xcmanage", {
    username: username.value,
    password: password.value,
  }).then((resp) => {
    if (resp.data.items.length === 1) {
      window.localStorage.setItem("islogin", true);
      window.localStorage.setItem("username", username.value);
      doLoad();
    } else {
      Toast.fail("账号密码错误");
    }
  });
};

const total = ref();
const hxmtotal = () => {
  console.log("运行了");
  Http.post("?s=App.PyszghMark_PyszghMark.HxmXCtotal", {
    username: window.localStorage.getItem("username"),
  }).then((resp) => {
    total.value = resp.data.total;
  });
};

const look = () => {
  console.log(total.value);
  Dialog.alert({
    title: "共核销 /张",
    message: `` + total.value,
  }).then(() => {
    // on close
  });
};
const islogin = ref(false);
const logout = () => {
  window.localStorage.clear();
  doLoad();
};

const doLoad = () => {
  var useragent = navigator.userAgent;
  if (useragent.match(/MicroMessenger/i) != "MicroMessenger") {
    // 这里警告框会阻塞当前页面继续加载
    // 以下代码是用javascript强行关闭当前页面
    // document.body.innerHTML = "仅允许在微信端访问";
    // var opened = window.open("about:blank", "_self");
    // opened.opener = null;
    // opened.close();
  }
  islogin.value =
    window.localStorage.getItem("islogin") === "true" ? true : false;
  if (window.localStorage.getItem("islogin") === "true") {
    hxmtotal();
  }
};
const show = ref(false);
const index = ref(0);

onBeforeMount(() => {
  doLoad();
});
</script>

<style scoped>
.wd {
  min-height: 100px;
  height: auto;
  background-size: 100%;
  background-repeat: repeat-y;
  padding: 5px 30px;
  color: white;
  font-size: 12px;
}
</style>
